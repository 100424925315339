import React, { useState, useEffect } from 'react';
import './Board.css';
import Tile from '../Tile/Tile'
import MiniTile from '../MiniTile/MiniTile'
import words from '../../util/words'
import firebase from '../../util/firebase.js'
import { document, window } from 'browser-monads';

const Board = () => {

  const tilesRef = firebase.database().ref('tiles');

  const shuffle = a => {
      for (let i = a.length - 1; i > 0; i--) {
          const j = Math.floor(Math.random() * (i + 1));
          [a[i], a[j]] = [a[j], a[i]];
      }
      return a;
  }

  const setNewBoard = () => {
    const types = shuffle(['red', 'red', 'red', 'red', 'red', 'red', 'red', 'blue', 'blue', 'blue', 'blue', 'blue', 'blue', 'blue', 'black', 'innocent', 'innocent',  'innocent',  'innocent', 'innocent', 'innocent', 'innocent', 'innocent', 'innocent',])
    if(numBlue > numRed){
      types.push('red')
    }
    else{
      types.push('blue')
    }
    createTiles(types)
  }

  const [tiles, setTiles] = useState([])
  const [test, setTest] = useState(0)
  const [numBlue, setNumBlue] = useState(0)
  const [numRed, setNumRed] = useState(0)
  let shuffledWords = shuffle(words)

  const createTiles = (types) => {
    let newTiles = []
    types.map((type, index) => {
      let newTile = {
        word: shuffledWords[index],
        type: type,
        status: false
      }
      newTiles.push(newTile)
    })
    tilesRef.set(newTiles);
  }

  const handleNewBoardClick = () => {
    let c = window.confirm("Are you sure you want to reset the board for everyone?")
    if(c){
      setNewBoard()
    }
  }

  const handleFlip = index => {
    let newTiles = tiles
    newTiles[index].status = true
    setTiles(newTiles)
    tilesRef.set(newTiles);
    setTest(test + 1)
  }

  const keyPress = () => {
    document.addEventListener("keydown", event => {
      if(event.key === 'k'){
        setNewBoard()
      }
    });
  }

  useEffect(() => {
    const itemsRef = firebase.database().ref('tiles');
    itemsRef.on('value', (snapshot) => {
      let items = snapshot.val()
      let newItems = []
      let getNumBlue = 0;
      let getNumRed = 0;
      items.forEach(item => {
        newItems.push(item)
        if(item.type === 'red' && item.status === false){
          getNumRed++
        }
        if(item.type === 'blue' && item.status === false){
          getNumBlue++
        }
      })
      if(tiles.length < 1){
        setTiles(items)
        setNumBlue(getNumBlue)
        setNumRed(getNumRed)
        keyPress()
      }
    })
  })

  return (
    <div className="table">
      <div className="Grid">
        {tiles.map( (tile, index) => {
          return (
            <Tile type={tile.type} word={tile.word} key={index} num={index} status={tile.status} flip={handleFlip} />
          )
        })}
      </div>
      <div className="Map">
        {tiles.map( (tile, index) => {
          return (
            <MiniTile type={tile.type}  />
          )
        })}
      </div>
      <div className="footer" >
      <p className="blue-tally">{numBlue > 0 ? numBlue + ' to go' : 'Blue wins!'}</p>
      <p className="new-board" onClick={() => handleNewBoardClick()}>✨ New Board</p>
      <p className="red-tally">{numRed > 0 ? numRed + ' to go' : 'Red wins!'}</p>
      </div>
    </div>
  )
}

export default Board;
