import React, {useEffect} from 'react';
import './Tile.css';

const Tile = ({...props}) => {

  // const [flipped, setFlipped] = useState(false);

  const renderTileClass = (type, status) => {
    console.log(status)
    if(status){
      switch (type) {
        case 'red':
          return 'Tile Tile--red Tile--flipped';
        case 'blue':
          return 'Tile Tile--blue Tile--flipped';
        case 'black':
          return 'Tile Tile--black Tile--flipped';
        default:
          return 'Tile Tile--innocent Tile--flipped'
      }
    }
    else{
      return 'Tile';
    }
  }

  return (
    <div className={renderTileClass(props.type, props.status)} onClick={() => props.flip(props.num)}><span>{props.word}</span></div>
  )
}

export default Tile;
