import React, {useEffect} from 'react';
import './MiniTile.css';

const MiniTile = ({...props}) => {

  // const [flipped, setFlipped] = useState(false);

  const renderTileClass = (type, status) => {
    switch (type) {
      case 'red':
        return 'MiniTile MiniTile--red';
      case 'blue':
        return 'MiniTile MiniTile--blue';
      case 'black':
        return 'MiniTile MiniTile--black';
      default:
        return 'MiniTile MiniTile--innocent'
    }
  }

  return (
    <div className={renderTileClass(props.type)}></div>
  )
}

export default MiniTile;
