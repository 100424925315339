import firebase from 'firebase'
var config = {
    apiKey: "AIzaSyCwDxmMnAYgXawgz6CLG35U8nCJvYL1tdM",
    authDomain: "codenames-jack.firebaseapp.com",
    databaseURL: "https://codenames-jack.firebaseio.com",
    projectId: "codenames-jack",
    storageBucket: "codenames-jack.appspot.com",
    messagingSenderId: "507677997913",
    appId: "1:507677997913:web:fc492a3472421a54b06a48"
  };
firebase.initializeApp(config);
export default firebase;
